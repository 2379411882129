(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Genders
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('Ajuntamentsread', Ajuntamentsread);

  function Ajuntamentsread($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/ajuntamentsread');
  }
}());
